//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import logoWhatsapp from '$resources/images/whatsapp.png';
import logoCompany from '$resources/images/logo-company.png';
import logoGesticob from '$resources/images/logo-company-gesticob.png';
import logoCMachao from '$resources/images/logo-company-cMachao.png';
import logoPseGreen from '$resources/images/logo-company-pseGreen.png';
import logoEseoRenovables from '$resources/images/logo-company-eseoRenovables.png';
import logoCoanatur from '$resources/images/logo-company-coanatur.png';
import logoJavierLeon from '$resources/images/logo-company-javierLeon.png';
import logoMesper from '$resources/images/logo-company-mesper.png';

const logoMapper = {
  'gesticob.ofertas.globeenergy.es': logoGesticob,
  'gesticob.ofertas.landing.neuro360.es': logoGesticob,
  'machao.ofertas.globeenergy.es': logoCMachao,
  'machao.ofertas.landing.neuro360.es': logoCMachao,
  'psegreen.ofertas.globeenergy.es': logoPseGreen,
  'psegreen.ofertas.landing.neuro360.es': logoPseGreen,
  'eseorenovables.ofertas.globeenergy.es': logoEseoRenovables,
  'eseorenovables.ofertas.landing.neuro360.es': logoEseoRenovables,
  'coanatur.ofertas.globeenergy.es': logoCoanatur,
  'coanatur.ofertas.landing.neuro360.es': logoCoanatur,
  'javierleon.ofertas.globeenergy.es': logoJavierLeon,
  'javierleon.ofertas.landing.neuro360.es': logoJavierLeon,
  'mesper.ofertas.globeenergy.es': logoMesper,
  'mesper.ofertas.landing.neuro360.es': logoMesper,
  default: logoCompany
};

export default {
  name: 'LandingLayout',
  data: () => ({
    logoWhatsapp
  }),
  methods: {
    openWhatsapp() {
      window.open('https://wa.me/+34?text=INFO', '_blank');
    },
    getLogo() {
      return logoMapper[window.location.host] || logoMapper.default;
    }
  }
};
